<template>
  <div class="deposit-detail">
    <b-row>
      <b-col
        cols="24"
        md="12"
      >
        <b-card title="Basic Info">
          <b-col cols="24 pt-0">
            <hr>
          </b-col>
          <div class="basic-content">
            <div>
              <b-card-text>Wallet ID: {{ basicInfo.transactionId }}</b-card-text>
              <b-card-text>Wallet status: {{ depositStatusText }}</b-card-text>
              <b-card-text>Amount: {{ basicInfo.amount }}</b-card-text>
              <b-card-text>Currency：{{ basicInfo.currency }}</b-card-text>
            </div>
            <div>
              <b-card-text>Channel: {{ basicInfo.depositChannelName }}</b-card-text>
              <b-card-text>Channel ID: {{ basicInfo.thirdpartyId }}</b-card-text>
              <b-card-text>Channel status: </b-card-text>
            </div>
            <div>
              <b-card-text>Create time: {{ createTime }}</b-card-text>
              <b-card-text>Update time: {{ updatedTime }}</b-card-text>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-card title="User Info">
          <b-col cols="24 pt-0">
            <hr>
          </b-col>
          <b-card-text>Country：{{ userInfo.country }}</b-card-text>
          <!-- class="text-primary" -->
          <b-card-text>User ID: {{ userInfo.uuid }}</b-card-text>
          <b-card-text>Document type: {{ userInfo.documentType }}</b-card-text>
          <b-card-text>Document ID: {{ documentId_f }}</b-card-text>
          <b-card-text>Email: {{ userInfo.email }}</b-card-text>
          <b-card-text>Cep: {{ userInfo.cep }}</b-card-text>
          <b-card-text>Phone number: {{ userInfo.phone }}</b-card-text>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-card title="History">
          <b-col cols="24 pt-0">
            <hr>
          </b-col>
          <b-table
            :items="history"
            :fields="fields"
            responsive
            class="history-table"
          >
            <template #row-details="row">
              <b-card>
                <b-row class="mb-2">
                  <b-col
                    md="8"
                    class="mb-1"
                  >
                    <strong>Status: </strong>
                  </b-col>
                  <b-col
                    md="8"
                    class="mb-1"
                  >
                    <strong>Date: </strong>
                  </b-col>
                  <b-col
                    md="8"
                    class="mb-1"
                  >
                    <strong>Remark: </strong>
                  </b-col>
                </b-row>
              </b-card>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col cols="12"
             md="6">
        <b-card title="Operation">
          <b-col cols="24 pt-0">
            <hr>
          </b-col>
          <b-form inline
                  class="userinfo-form">
            <label class="mr-sm-1 mb-1"
                   for="inline-form-custom-select-pref">Select status：</label>
            <v-select v-model="operationForm.selectStatus"
                      :options="selectStatusOption"
                      class="invoice-filter-select mb-1 mr-sm-1"
                      placeholder="All">
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
            <br />
            <label class="mr-sm-1 mb-1"
                   for="inline-form-custom-select-pref">Remark：</label>
            <b-form-input v-model="operationForm.remark"
                          class="d-inline-block mr-1 mb-1"
                          placeholder="" />
            <br />
            <label class="mr-sm-1 mb-1"
                   for="inline-form-custom-select-pref">Password：</label>
            <b-form-input v-model="operationForm.password"
                          class="d-inline-block mr-1 mb-1"
                          placeholder="" />
            <b-button variant="primary"
                      class="mb-1 mr-2">Submit</b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row> -->
  </div>
</template>
<script>
import vSelect from 'vue-select'
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BTable,
  BForm,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import {
  getQueryString,
  isDefine,
  updateDate,
  formatDocumentId,
  formatPhoneNumber,
} from '@/libs/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BTable,
    BForm,
    vSelect,
    BFormInput,
    BButton,
  },
  data() {
    return {
      fields: ['status', 'Date', 'Remark'],
      // selectStatusOption: ["Fail", "Created", "Processing", "Success"],
      // operationForm: {
      //   selectStatus: null,
      //   remark: null,
      //   password: null,
      // },
      basicInfo: {},
      userInfo: {},
      history: [],
      depositStatus: {
        '-1': 'All',
        0: 'Pending',
        1: 'Success',
        2: 'Rejected',
        3: 'Processing',
      },
    }
  },
  computed: {
    depositStatusText() {
      switch (this.basicInfo.depositStatus) {
        case -1:
          return 'All'
          break
        case 0:
          return 'Pending'
          break
        case 1:
          return 'Success'
          break
        case 2:
          return 'Rejected'
          break
        case 3:
          return 'Processing'
          break
        default:
          return ''
          break
      }
    },
    createTime() {
      return updateDate(this.basicInfo.createdAt, getQueryString('timezone'))
    },
    updatedTime() {
      return updateDate(this.basicInfo.updatedAt, getQueryString('timezone'))
    },
    documentId_f() {
      return formatDocumentId(this.userInfo.documentId, this.basicInfo.currency)
    },
    phone_f() {
      return formatPhoneNumber(this.userInfo.phone)
    },
  },
  mounted() {
    this.initDepositDetail()
  },
  methods: {
    // 初始化deposit详情
    initDepositDetail() {
      this.$showLoading()
      const { id } = this.$route.query
      this.$http
        .get(`/api/v1/deposit/details?transactionId=${id}`)
        .then(response => {
          this.$hideLoading()
          if (response.data.code == 200) {
            const { basicInfo, userInfo, history } = response.data.data
            this.basicInfo = basicInfo
            this.userInfo = userInfo
            this.history = this.handlerHistory(history)
          } else {
            this.showToast('warning', response.data.message)
          }
        })
        .catch(error => {
          this.$hideLoading()
          this.showToast('warning', error.message)
        })
    },
    // 处理history数据
    handlerHistory(bean) {
      if (!bean) return
      bean.forEach(o => {
        if (isDefine(o.transactionStatus)) {
          o.status = this.depositStatus[o.transactionStatus]
        } else {
          o.status = ''
        }
        if (!isDefine(o.message)) {
          o.message = ''
        }
        o.Date = updateDate(o.createdAt, getQueryString('timezone'))
      })
      return bean
    },
    showToast(variant = null, message = '') {
      this.$bvToast.toast(`${message}`, {
        title: `${variant || 'Error'}`,
        variant,
        solid: true,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.deposit-detail {
  width: 80%;
  padding: 4rem 0;
  margin: 0 auto;
  min-height: 400px;
  .basic-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  ::v-deep .history-table {
    word-break: break-all;
    word-wrap: break-all;
    .table th,
    .table td {
      padding: 10px !important;
    }
    .table tr td:nth-of-type(1) {
      width: 7.2rem;
    }
    .table tr td:nth-of-type(2) {
      width: 12.7rem;
    }
  }
  .per-page-selector {
    width: 90px;
  }
  .userinfo-form label {
    width: 100px;
    justify-content: left;
  }
  .invoice-filter-select {
    min-width: 280px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  .d-inline-block {
    width: 280px;
  }
}
</style>
